// grid col 追加項目
.col-xs-1-5, .col-sm-1-5, .col-md-1-5, .col-lg-1-5,
.col-xs-2-5, .col-sm-2-5, .col-md-2-5, .col-lg-2-5,
.col-xs-3-5, .col-sm-3-5, .col-md-3-5, .col-lg-3-5,
.col-xs-4-5, .col-sm-4-5, .col-md-4-5, .col-lg-4-5 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}	
.col-xs-1-5 {
	width: 20%;
	float: left;
}
.col-xs-2-5 {
	width: 40%;
	float: left;
}
	
.col-xs-3-5 {
	width: 60%;
	float: left;
}
	
.col-xs-4-5 {
	width: 80%;
	float: left;
}
@media (min-width: 768px) {
	.col-sm-1-5 {
		width: 20%;
		float: left;
	}
	col-sm-2-5 {
		width: 40%;
		float: left;
	}
	col-sm-3-5 {
		width: 60%;
		float: left;
	}
	col-sm-4-5 {
		width: 80%;
		float: left;
	}
}
@media (min-width: 992px) {
	.col-md-1-5 {
		width: 20%;
		float: left;
	}
	.col-md-2-5 {
		width: 40%;
		float: left;
	}
	.col-md-3-5 {
		width: 60%;
		float: left;
	}
	.col-md-4-5 {
		width: 80%;
		float: left;
	}
}
@media (min-width: 1200px) {
	.col-lg-1-5 {
		width: 20%;
		float: left;
	}
	.col-lg-2-5 {
		width: 40%;
		float: left;
	}
	.col-lg-3-5 {
		width: 60%;
		float: left;
	}
	.col-lg-4-5 {
		width: 80%;
		float: left;
	}
}