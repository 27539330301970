#main-header{
  position:absolute;
  width:100%;
  z-index:998;
  #mobile-logo{
      position: relative;
      background: url(../img/mobile-logo.png) center 0 no-repeat;
      width: 200px;
      height: 55px;
      margin: 10px auto 5px;
      a{
          display: block;
          width: 100%;
          height: 100%;
      }
  }
  #translate{
      position:absolute;
      top:18px;
      right:500px;
      @media (max-width: $screen-sm-min) {
          position: relative;
          top:0px;
          right:0px;
          text-align: center;
      }
  }
  #searchbar{
      position:absolute;
      top:20px;
      right:306px;
      .form-control{
          float:left;
          @include border-top-radius(18px);
          @include border-bottom-radius(18px);
          border:$light solid 1px;
          height:30px;
          line-height:28px;
          width:148px;
          padding:0 10px;
      }
      button{
          border:0 none;
          float:left;
          width:30px;
          height:30px;
          margin-left:1px;
          background:url(../img/icon-search.png) 0 0 no-repeat;
          &:hover{
              background-position:1px 1px;
          }
      } //button
      @media (max-width: $screen-sm-min) {
          top:60px;
          //left:60px;
          right:10px;
      }
  } // #searchbar
  .advance-search{
      position:absolute;
      top:20px;
      right:308px;
      .button{
          height:30px;
          width:80px;
          text-align:center;
          line-height:30px;
          @include border-top-radius(18px);
          @include border-bottom-radius(18px);
          background-color:$darkest;
          font-size:12px;
          cursor:pointer;
          a{
              color:$lightest;
              &:hover{
                  text-decoration:none;
              }
          }
      }
      @media (max-width: $screen-xs-min) {
          top:10px;
          left:240px;
          right:0;
      }
  } // .advance-search
  .member-panel{
      position:absolute;
      top:20px;
      right:138px;
      border:$light solid 1px;
      @include border-top-radius(18px);
      @include border-bottom-radius(18px);
      height:30px;
      width:160px;
      line-height:28px;
      overflow:hidden;
      font-size:12px;
      background-color:$lightest;
      a{
          color:$darkest;
          &:hover{
              text-decoration:none;
              color:$dark;
          }
      }
      .icon-lock{
          float:left;
          width:32px;
          height:30px;
          background:$darkest url(../img/icons.png) 0 -2px no-repeat;
      }
      .login , .logout{
          float:left;
          margin-left:5px;
      }
      .signin , .member{
          float:left;
      }
      .spacer{
          float:left;
          padding:0 5px;
      }
      @media (max-width: $screen-sm-min) {
          display: none;
      }
  } // .member-panel
  .cart{
      position:absolute;
      top:20px;
      right:30px;
      border:$light solid 1px;
      @include border-top-radius(18px);
      @include border-bottom-radius(18px);
      height:30px;
      width:100px;
      overflow:hidden;
      background-color:$lightest;
      .icon{
          float:left;
          width:32px;
          height:28px;
          background:$highlight url(../img/icons.png) -36px -2px no-repeat;
          a{
              display:block;
              width:100%;
              height:100%;
          }
      }
      .amount{
          float:left;
          font-weight:bold;
          height:30px;
          line-height:28px;
          margin-left:5px; 
          a{
              color:$highlight;
          }
      }
      @media (max-width: $screen-sm-min) {
          display: none;
      }
  } //.cart
}