#menubar{
  position:absolute;
  z-index:999;
  top:30px;
  left:10%;
  width:195px;
  // .background-opacity(#ffffff,0.3);
  padding:10px;
  .logo{
      position:relative;
      width:116px;
      height:95px;
      margin:0 auto;
      img{
          width:100%;
      }
  }
  #menu-btn{
      position:absolute;
      width:45px;
      top: 120px;
      left:205px;
      height:45px;
      border-radius:4px;
      cursor:pointer;
      .btn{
          position:relative;
          display:block;
          width:39px;
          height:39px;
          border:0 none;
          background:url(../img/btns.png) -311px -5px no-repeat;
      }
  }
  .main-menu{
      position:relative;
      width:175px;
      padding:0;
      margin-top:40px;
      border-bottom:$darkest solid 1px;
      @media (max-width: $screen-sm-min) {
          //display:none;
          margin:10px auto 0;
      }
      li{
          position: relative;
          width:175px;
          line-height:50px;
          border-top:$darkest solid 1px;
          list-style-type:none;
          text-align:center;
          font-size:16px;
          font-weight:600;
          overflow: hidden;
          &.group{
              cursor:pointer;
              &:hover{
                  //height: auto;
              }
          }
          &.current{
              height: auto;
          }
          a{
              text-decoration:none;
              color:$darkest;
              &:hover{
                  color:$highlight;
              }
          }
          .school-select{
              padding: 10px 20px 20px;
              .divider{
                  padding-top:5px;
              }
          }
          @media (max-width: $screen-xs-max) {
          }
          @media (max-width: $screen-sm-min) {
              line-height: 30px;
          }
      } // li
      .submenu{
          position: relative;
          display: none;
          padding:0;
          margin:0 auto;
          overflow: hidden;
          li{
              width:auto;
              max-width:150px;
              border-top:$darkest dotted 1px;
              border-bottom:0 none;
              font-size:14px;
              margin:0 auto;
              ul{
                  border-top:$darkest dotted 1px;
                  li{
                      border: none;
                      line-height: 30px;
                      height: 30px;
                      text-align: left;
                      margin-left: 8px;
                  }
              }
          }
          //border-bottom:$darkest solid 1px;
      } //ul.submenu
      &.unfold{
          ul.submenu{
              display: block;
          }
      }
  } // .main-menu

  &.light{
      background:none;
  }
  @media (max-width: $screen-sm-min) {
      top:0;
      left:-195px;
      width:auto;
      background-color: #ccc;
      .logo{
          display: none;
      }
  }
} //#menubar
#menubar {
	background-color:#fff;
	top: 60px;
	.logo {
		margin-top: 8px;
	}
	.main-menu {
		margin-top: 20px;
		margin-bottom: 0;
		border-bottom: 0;
		li {
			overflow: visible;
			&:hover {
				>.submenu {
					display: block;
				}
			}
			.submenu {
				// display: block;
				position: absolute;
				top: 0;
				left: 175px;
				background-color: rgba(255, 255, 255, 0.85);
				overflow: visible;
				li {
					width: 175px;
					line-height: 1.3em;
					padding: 16px 0;
					border-top: #363636 solid 1px;
					&.group {
						&:hover {
							>li {
								display: block;
							}
						}
					}
					&:first-child {
						border-top: none;
					}
					a {
						color: #000;
						&:hover {
							color: #474747;
						}
					}
					ul {
						border-top:none;
						left:150px;
						li {
							border-top: #363636 solid 1px;
							line-height: 1.3em;
							text-align: center;
							margin-left: auto;
							height: auto;
						}
					}
				}
			}
		}	
	}
}
.overlay {
    position: absolute;
    z-index: 998;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    animation: overlay-fade-in 0.5s 1;
}

@keyframes overlay-fade-in {
    0% { opacity: 0}
    100% { opacity: 1}
}