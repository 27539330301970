// others
.no-padding {
	padding: 0 !important;
}

body {
  position:absolute !important;
  width:100%;
  height:100%;
  font-family:$font-family-sans-serif;
	background:$border-color;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

#top-nav {
	border-radius: 0;
	background-color: #000;
	margin-bottom: 0;
	font-weight: bolder;
	.nav>li {
		padding-left: 6px;
		padding-right: 6px;
	}
	.active{
		a:focus {
			background-color: #000 !important;
		}
	}
	.dropdown-menu {
		background-color: rgba(0,0,0,0.75);
		a {
			color: #fff;
		}
		li {
			a:hover {
				background-color: rgba(0,0,0,1);
				color: #fff;
			}
		}
	}
	.dropdown:hover>.dropdown-menu {
	    display: block;
	    margin-top: 0; // remove the gap so it doesn't close
	 }
}

.divider {
	height: 30px;
	width: 100%;
}

.button {
  border: 0;
  font-size:12px;
  padding: 0 1em;
  @include center-block();
  color: #fff;
  text-decoration: none;
  position: relative;
}
.icon{
  display:inline-block;
}
.item-box  {
	margin-bottom: 30px;
	@include item-box-shadow;
	.box-top{
		border-bottom: $border-color 1px solid;
	}
	.box-bottom {
		height: 75px;
		padding-top: 18px;
		background-color: #fff;
		overflow: hidden;
		.box-left {
			color: #cc0000;
			font-size: 16px;
			font-weight: bold;
			a {
				color: #cc0000;
			}
		}
		.box-right {
			font-size: 30px;
			a {
				color: #000000;
			}
		}
	}
}

.social {
	margin-top: 20px;
	.fb-banner {
		width: 100%;
		height: 80px;
		background: url(../img/fb-banner.gif) center center no-repeat;
		@include item-box-shadow;
	}
	.line-banner {
		width: 100%;
		height: 80px;
		background: url(../img/line-banner.gif) center center no-repeat;
		@include item-box-shadow;
	}
}

.category{
	padding: 10px;
	background-color: #000000;
	a{
			color: #FFFFFF;
			text-decoration: underline;
	}
}

.shelf{
	position:relative;
	width:100%;
	padding:30px 0 40px;
	border-top:$light 1px solid;
	header{
			width:100%;
			line-height:36px;
			height:36px;
			.title{
				@include make-xs-column(12);
					background:url(../img/headers.png) 0px -120px no-repeat;
					text-indent:-9999px;
			}
			.control{
				@include make-xs-column(8);
					.prev{
							position:absolute;
							right:40px;
							width:33px;
							height:33px;
							background:url(../img/btns.png) 0 -8px no-repeat;
							&:hover{
									background-position:-100px -8px;
									cursor:pointer;
							}
					}
					.next{
							position:absolute;
							right:0;
							width:33px;
							height:33px;
							background:url(../img/btns.png) -41px -8px no-repeat;
							&:hover{
									background-position:-148px -8px;
									cursor:pointer;
							}
					}
			}
	}
	.products{
		@include make-row();
			margin-top:10px;
			.item{
				@include make-sm-column(3);
				@include make-xs-column(12);
				margin-bottom: 20px;
				min-height: 349px;
				.box-top{
						width:100%;
						padding:8px;
						color:$darker;
						border-bottom:$light 1px solid;
						.name{
							position: relative;
							margin-top: 5px;
							font-weight:bold;
							font-size:15px;
							line-height:1.2em;
							text-align:center;
							padding:5px 0 3px;
							height: 2.8em;
							overflow: hidden;
						}
				}
				.box-bottom{
					width:100%;
					height:40px;
					.price{
							float:left;
							line-height:40px;
							text-decoration:line-through;
							padding-left:10px;
					}
					.sprice{
							float:right;
							line-height:40px;
							color:$highlight;
							font-size:20px;
							font-weight:bold;
							padding-right:10px;
							small{
									font-size:13px;
							}
					}
					.like{
							float:right;
							width:40px;
							height:40px;
							border-left:$light 1px solid;
							background:url(../img/product-btns.png) -5px -5px no-repeat;
							cursor:pointer;
							&:hover{
									background-position:-105px -5px;
							}
					} // .like
					.buy{
							float:right;
							width:40px;
							height:40px;
							border-left:$light 1px solid;
							background:url(../img/product-btns.png) -55px -5px no-repeat;
							cursor:pointer;
							&:hover{
									background-position:-155px -5px;
							}
					}
					.nis{
							padding: 10px;
							text-align: center;
					}
				}
			} //.item
	} //.products
	.hotsale{
			margin-top:30px;
			header .title{
					background-position:0 -160px;
			}
	}
	.special {
			margin-top:30px;
			header .title.expiring {
					background-position:0 -240px;
			}
			header .title.daily_event {
					background-position:0 -280px;
			}
	}
	
} //.shelf

.new-arrivals {
	margin-top: 20px;
	h4 {
		font-weight: 600;
		font-size: 32px;
		background: url(../img/title-line.gif) 0 center repeat-x;
		margin-bottom: 20px;
		.text {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: $border-color;
		}
	}
	.product-box {
		.box-bottom {
			padding-bottom: 30px;
		}
		&.box-s {
			.box-top{
				border-bottom: #ccc 1px solid;
				padding: 0;
				img {
					height: 140px;
				}
			}
			.box-bottom {
				padding-top: 20px;
				padding-bottom: 20px;
			}
			.name {
				height: 55px;
			}
			.sprice {
				font-size: 22px;
				line-height: 80px;
			}
		}
	}
}
.surprise {
	margin-top: 20px;
	h4 {
		font-weight: 600;
		font-size: 32px;
		background: url(../img/title-line.gif) 0 center repeat-x;
		margin-bottom: 20px;
		.text {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: $border-color;
		}
	}
	.product-box {
		.box-bottom {
			// height: 120px;
			padding-top: 10px;
			padding-bottom: 10px;
		}	
	}
	.more {
		height: 355px;
		background:#c6010b url(../img/more.gif) center 30px no-repeat;
	}
	
}
.bonbonbuy {
	margin-top: 20px;
	h4 {
		font-weight: 600;
		font-size: 32px;
		background: url(../img/title-line.gif) 0 center repeat-x;
		margin-bottom: 20px;
		.text {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: $border-color;
		}
	}
	.product-box {
		.box-bottom {
			padding-top: 21px;
			height: 102px;
			.sprice {
				// line-height: 66px;
			}
		}
		&.box-w {
			.box-bottom {
				padding-top: 10px;
				padding-bottom: 10px;
				height: auto;
				.name {
					height: 20px;
				}
				.price {
					margin-top: 8px;
				}
				.sprice {
					line-height: 44px;
				}
			}
		}
		&.box-s {
			.box-bottom {
				height: 86px;
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
	}
}
.hot-sale {
	margin-top: 20px;
	h4 {
		font-weight: 600;
		font-size: 32px;
		background: url(../img/title-line.gif) 0 center repeat-x;
		margin-bottom: 20px;
		.text {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: $border-color;
		}
	}
	.product-box {
		.box-bottom {
			padding-bottom: 30px;
		}
		&.box-s {
			.box-top{
				border-bottom: #ccc 1px solid;
				padding: 0;
				img {
					
				}
			}
			.box-bottom {
				padding-top: 20px;
				padding-bottom: 20px;
			}
			.name {
				height: 55px;
			}
			.sprice {
				font-size: 22px;
				line-height: 80px;
			}
		}
	}
}

.review {
	margin-top: 20px;
	h4 {
		font-weight: 600;
		font-size: 32px;
		background: url(../img/title-line.gif) 0 center repeat-x;
		margin-bottom: 20px;
		.text {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: $border-color;
		}
	}
	.item-box {
		.box-bottom {
			// height: 120px;
			padding-top: 15px;
			padding-bottom: 15px;
			height: auto;
			.box-left {
				a {
					color: #000;
				}
			}
			.box-right {
				a {
					color: #cc0000;
				}
			}
		}	
	}
}

.welfare {
	margin-top: 20px;
	h4 {
		font-weight: 600;
		font-size: 32px;
		background: url(../img/title-line.gif) 0 center repeat-x;
		margin-bottom: 20px;
		.text {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: $border-color;
		}
	}
	.item-box {
		.box-bottom {
			padding-top: 5px;
			height: 65px;
			.box-left {
				.title {
					overflow: hidden;
					height: 36px;
					line-height: 18px;
				}
				p{
					color: #000;
					font-size: 13px;
					line-height: 15px;
				}
			}
		}
		&.box-w {
			.box-bottom {
				height: 70px;
				.box-left {
					.title {
						line-height: 36px;
					}
				}
			}
		}
	}
}
.pets-school {
	margin-top: 20px;
	h4 {
		font-weight: 600;
		font-size: 32px;
		background: url(../img/title-line.gif) 0 center repeat-x;
		margin-bottom: 20px;
		.text {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: $border-color;
		}
	}
	.pets-school-selected {
		width: 50%;
		float: left;
	}
	.school-select {
		width: 50%;
		padding: 0 30px;
		float: right;
		height: 277.5px;
		text-align: center;
		background: #D7D3D0; /* Old browsers */
		.title {
			margin-top: 20px;
			margin-bottom: 10px;
			font-size: 18px;
			font-weight: bold;
			line-height: 30px;
		}
		select {
			outline: none;
			border: 0px none;
			line-height: 1.42857143;
			padding: 6px 12px;
			font-size: 14px;
			display: block;
			width: 100%;
			height: 34px;
			// background-color: transparent;
		}
	}
}
.charity {
	h4 {
		font-weight: 600;
		font-size: 32px;
		background: url(../img/title-line.gif) 0 center repeat-x;
		margin-top: 20px;
		margin-bottom: 15px;
		.text {
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background-color: $border-color;
		}
	}
	.item-box {
		.box-bottom {
			padding-top: 10px;
			padding-left: 10px;
			padding-right: 10px;
			height: 65px;
			font-size: 16px;
		}
	}
}

.shelf {
	.item {
		.box-top {
			.pic{
				img {
					@include item-box-shadow;
				}
			}
		}
	}
}

#brand-content {
	.explanation {
		.nav {
			li.active a{
				background-color: transparent;
			}
		}
	}	
}
#product-content {
	.detail {
		.nav {
			li.active a{
				background-color: transparent;
				border-bottom: 0 none;
			}
		}
	}
}

@media (max-width: 768px) {
	.pets-school {
		.pets-school-selected {
			width: 100%;
			float: none;
		}
		.school-select {
			width: 100%;
			float: none;
		}
	}
}