#member-content{
  margin-bottom:40px;
  min-height:750px;
  .head{
      position:relative;
      margin-top:100px;
      height:55px;
      border-bottom:$light 1px solid;
      .title{
          float:left;
          color:$dark;
          font-size:36px;
          @media (max-width: $screen-xs-min) {
              float:none;
          }
      }
      .breadcrumb{
          @media (max-width: $screen-xs-min) {
              float:none;
              height:30px;
              line-height:30px;
              margin:0;
              padding:0;
          }
      }
      @media (max-width: $screen-xs-max) {
          height:auto;
          margin-top:230px;
          overflow:hidden;
      }
  } //.head
  
  .explanation{
      position:relative;
      line-height:30px;
      padding:20px 0;
      color:$darker;
      .topic{
          position:relative;
          margin-top:30px;
          height:40px;
          line-height:40px;
          color:$highlight;
          font-size:15px;
          font-weight:bold;
          .icon{
              float:left;
              width:24px;
              height:40px;
              background:url(../img/icons.png) -113px 5px no-repeat;
          }
      } //.topic
      ol{
          padding:0;
          margin:0 0 0 28px;
          li{
              line-height:30px;
          }
      }
  } //.explanation
  .member-info-form{
      position:relative;
      margin-top:10px;
      font-size:15px;
      label{
          margin-top:20px;
          .mark{
              font-weight:bold;
              color:$highlight;
              font-variant:small-caps;
              background-color:transparent;
          }
      }
      .input-title{
          @include make-xs-column(12);
      }
      .private-box{
          border-radius:4px;
          border:$light 1px solid;
          padding:10px;
          height:300px;
          overflow:auto;
      }
      .checkbox{
          label{
              margin-top:0;
          }
      }
      .control-btns{
          margin-top:20px;
          .btn-reset{
              width:157px;
              height:47px;
              border-radius:36px;
              background-color:$brand-warning;
              color:$lightest;
              font-size:15px;
              font-weight:bold;
              margin-right:10px;
          }
          .btn-submit{
              width:157px;
              height:47px;
              border-radius:36px;
              background-color:$highlight;
              color:$lightest;
              font-size:15px;
              font-weight:bold;
          }
      }
      .my-pets{
          position:relative;
          display: none;
          margin-top:15px;
          padding-left:20px;
          h4{
              font-size:18px;
              font-weight:500;
          }
      }
      .pets-info{
          position:relative;
          margin-top:15px;
          padding-left:20px;
          h4{
              color:$highlight;
              font-size:15px;
          }
          table{
              width:100%;
              td{
                  width:auto;
                  padding:5px 0;
                  label{
                      margin-top:0;
                  }
                  .pet-age{
                      display:inline-block;
                      width:100px;
                  }
              }
          }
      }
      #pets-num{
          display:inline-block;
          width:100px;
      }
      
  } //.member-info-form
  
  .member-panel{
      margin-top:30px;
      .topic{
          height:46px;
          padding:12px 0;
          border-radius:4px;
          background-color:$lighter;
          font-size:15px;
          text-align:center;
          >div{
              border-left:$light 1px solid;
              &:first-child{
                  border-left:0 none;
              }
          }
      }
      ul.menu{
          margin:0 0 30px;
          padding:0;
          li{
              color:$light;
              list-style-type:none;
              line-height:30px;
              padding-top:10px;
              border-bottom:$light 1px dashed;
              &::before{
                  content:" - ";
              }
              a{
                  color:$dark;
                  &:hover{
                      color:$highlight;
                      text-decoration:none;
                  }
              }
          }
      }
      .banner{
        @include make-sm-column(12);
        @include make-xs-column(6);
          text-align:center;
          margin-bottom:20px;
      }
      .bottom{
          height:371px;
          background:url(../img/member-panel-bottom.jpg) 0 0 no-repeat;
      }
  } //.member-panel
  .bouns-info,.coupon-info,.order-info,.cart-info{
      margin-top:30px;
  }
  
  table.info-table{   
      overflow:hidden;
      border-collapse:separate;
      width:100%;
      border-radius:6px;
      border:$light 1px solid;
      font-size:15px;
      thead{
          th{
              background-color:$highlight;
              color:$lightest;
              border-left:#fff 1px solid;
          }
      }
      tbody{
          th{
              font-weight:bold;
              color:$darkest;
          }
      }
      tr:first-child{
          th,td{
              border-top:0 none;
          }
      }
      th,td{
          padding:0 15px;
          height:45px;
          border-left:$light 1px solid;
          border-top:$light 1px solid;
          color:$dark;
          overflow:hidden;
          &:first-child{
              border-left:0 none;
          }
      }
      td.order-products{
          padding:0;
      }
      ul.order-list{
          padding:0;
          margin:0;
          li{
              list-style-type:none;
              border-top:$light 1px solid;
              &:first-child{
                  border-top:0 none;
              }
          }
      }
      .item-name{
          width:180px;
      }
      .num{
          width:65px;
      }
      .status{
          width:100px;
      }
      span.item-name{
          width:180px;
      }
      span.num{
          padding-left:15px;
      }
      span.status{
          width:85px;
          padding-left:15px;
          line-height:35px;
          display:inline-block;
      }
      span.item-name , span.num {
          display:inline-block;
          padding-left:15px;
          line-height:35px;
          border-right:$light 1px solid;
      }
      a{
          color:$dark;
          text-decoration:underline;
      }
      .order-no{
          font-size:12px;
      }
      .delete-btn{
          position:relative;
          width:31px;
          height:31px;
          margin:0 auto;
          background:url(../img/btns.png) -449px center no-repeat;
          cursor:pointer;
          &:hover{
              background-position:-448px -8px;
          }
      }
      tfoot{
          td{
              &:first-child{
                  border-top:$light 1px solid;
              }
          }
          ul{
              text-align:right;
              li{
                  list-style-type:none;
                  font-size:16px;
                  .price{
                      color:$highlight;
                  }
              }
          }
      }
  } //table.info-table
  .order-info{
      .order-check{
          position:relative;
          .filter{
              position:relative;
              height:30px;
              line-height:30px;
              margin-bottom:10px;
              .topic{
                  padding-right:20px;
              }
              label{
                  font-weight:normal;
                  margin-right:10px;
              }
              input[type=text]{
                  margin-left:10px;
                  width:150px;
                  display:inline-block;
              }
          }
          .btn-check{
              width:157px;
              height:47px;
              border-radius:36px;
              background-color:$highlight;
              color:$lightest;
              font-size:16px;
              font-weight:bold;
          }
      } //.order-check
      .result{
          height:40px;
          line-height:40px;
          color:$highlight;
          border-top:$light 1px solid;
          margin-top:10px;
          padding-top:5px;
          font-weight:bold;
          .icon{
              float:left;
              width:24px;
              height:40px;
              background:url(../img/icons.png) -113px 5px no-repeat;
          }
      } //.result
      table{
          tbody{
              td{
                  font-size:13px;
              }
          }
      }
      > .topic{
          position:relative;
          margin-top:30px;
          height:40px;
          line-height:40px;
          color:$highlight;
          font-size:15px;
          font-weight:bold;
          .icon{
              float:left;
              width:24px;
              height:40px;
              background:url(../img/icons.png) -113px 5px no-repeat;
          }
      } //.topic
      .order-top{
          padding:10px 0;
          color:$dark;
          .order-id{
              margin-left:5px;
          }
          .order-date{
              margin-left:20px;
          }
      }
      .total{
          position:relative;
          padding:20px 0;
          text-align:center;
          font-size:18px;
          border-bottom:$light 1px solid;
          .price{
              color:$highlight;
          }
      }
  } //.order-info
  
  .login-panel{
      position:relative;
      margin-top:30px;
      .register-section{
          margin-right:20px;
          .content{
              position:relative;
              padding-top:20px;
              text-align:right;
              line-height:24px;
              min-height:600px;
              background:url(../img/login-bg.jpg) -20px bottom no-repeat;
              .btn-event{
                  width:157px;
                  height:47px;
                  line-height:32px;
                  border-radius:36px;
                  background-color:$brand-warning;
                  color:$lightest;
                  font-size:16px;
                  font-weight:bold;
                  margin-bottom:10px;
              }
              .btn-reg{
                  width:157px;
                  height:47px;
                  line-height:32px;
                  border-radius:36px;
                  background-color:$highlight;
                  color:$lightest;
                  font-size:16px;
                  font-weight:bold;
              }
          }
      }
      .login-section{
          .topic{
              .icon{
                  background-position:-180px 4px;
              }
          }
          form{
              margin-top:20px;
          }
          label{
              font-size:16px;
              color:$dark;
              .mark{
                  font-weight:bold;
                  color:$highlight;
                  font-variant:small-caps;
                  background-color:transparent;
              }
          }
          .lost-pw{
              float:left;
              line-height:25px;
              .icon{
                  float:left;
                  width:24px;
                  height:25px; 
                  background:url(../img/icons.png) -222px -5px no-repeat;
              }
              a{
                  color:$dark;
              }
          }
          .btn-login{
              float:right;
              width:157px;
              height:47px;
              line-height:32px;
              border-radius:36px;
              background-color:$highlight;
              color:$lightest;
              font-size:16px;
              font-weight:bold;
              margin-top:5px;
          }
          .social-login{
              position: relative;
              margin-top: 20px;
              .fb{
                  position: relative;
                  float: left;
                  width: 171px;
                  height: 72px;
                  background: url(../img/login-btn-fb.png) 0 0 no-repeat;
                  cursor: pointer;
              }
              // .gplus{
              //     position: relative;
              //     float: left;
              //     margin-left: 20px;
              //     width: 153px;
              //     height: 73px;
              //     background: url(../img/login-btn-gplus.png) 0 0 no-repeat;
              // }
          }
      }
      .topic{
          height:55px;
          line-height:40px;
          color:$gray-light;
          font-weight:lighter;
          font-size:36px;
          padding-bottom:15px;
          border-bottom:$light 1px dotted;
          .icon{
              float:left;
              width:36px;
              height:40px;
              margin-right:5px;
              background:url(../img/icons.png) -144px 4px no-repeat;
          }
      }
  } //.login-panel
  
  .cart-info{
      #bonus{
          padding: 5px 0 15px;
          strong{
              color: $highlight;
          }
      }
      #coupon{
          padding: 5px 0 15px;
      }
      #none{
          padding: 5px 0 20px;
      }
      .info-table{
          th,td{
              text-align:center;
          }
          td{
              padding:10px 15px;
          }
          .promotion{
              color:$highlight;
          }
          a{
              text-decoration: none;
          }
          .qty-input{
              font-size: 12px;
              option{
                  padding: 0 10px;
              }
          }
      }
      .control-btns{
          margin-top:20px;
          .btn-reset{
              width:157px;
              height:47px;
              border-radius:36px;
              background-color:$brand-warning;
              color:$lightest;
              font-size:15px;
              font-weight:bold;
              margin-right:10px;
          }
          .btn-submit{
              width:157px;
              height:47px;
              border-radius:36px;
              background-color:$highlight;
              color:$lightest;
              font-size:15px;
              font-weight:bold;
          }
      }
  } //.cart-info
  
  .activation-panel{
      position:relative;
      margin-top:30px;
      line-height:36px;
      min-height:450px;
      color:$dark;
      background:url(../img/activation-bg.jpg) right bottom no-repeat;
      .control-btns{
          margin-top:20px;
          .btn-resend{
              width:157px;
              height:47px;
              border-radius:36px;
              background-color:$brand-warning;
              color:$lightest;
              font-size:15px;
              font-weight:bold;
              margin-right:10px;
          }
          .btn-activate{
              width:157px;
              height:47px;
              border-radius:36px;
              background-color:$highlight;
              color:$lightest;
              font-size:15px;
              font-weight:bold;
          }
      }
  } //.activation-panel
  
  .contact-form{
      position:relative;
      margin-top:30px;
      .control-btns{
          margin-top:20px;
          .btn-reset{
              width:157px;
              height:47px;
              border-radius:36px;
              background-color:$brand-warning;
              color:$lightest;
              font-size:15px;
              font-weight:bold;
              margin-right:10px;
          }
          .btn-submit{
              width:157px;
              height:47px;
              border-radius:36px;
              background-color:$highlight;
              color:$lightest;
              font-size:15px;
              font-weight:bold;
          }
      }
  } //.contact-form
}
@media (max-width: $screen-sm-min) {
  #member-content .head {
      height: auto;
      margin-top: 30px;
      text-align: center;
      overflow: hidden;
  }
}