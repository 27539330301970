#rule-content{
  margin-bottom:40px;
  min-height:750px;
  .head{
      position:relative;
      margin-top:100px;
      height:55px;
      border-bottom:$light 1px solid;
      .title{
          float:left;
          color:$dark;
          font-size:36px;
          @media (max-width: $screen-xs-min) {
              float:none;
          }
      }
      .breadcrumb{
          @media (max-width: $screen-xs-min) {
              float:none;
              height:30px;
              line-height:30px;
              margin:0;
              padding:0;
          }
      }
      @media (max-width: $screen-xs-max) {
          height:auto;
          margin-top:230px;
          overflow:hidden;
      }
  } //.head
  
  .explanation{
      position:relative;
      line-height:30px;
      padding:20px 0;
      color:$darker;
      .topic{
          position:relative;
          margin-top:20px;
          height:40px;
          line-height:40px;
          color:$highlight;
          font-size:15px;
          font-weight:bold;
          .icon{
              float:left;
              width:24px;
              height:40px;
              background:url(../img/icons.png) -113px 5px no-repeat;
          }
          &:first-child{
               margin-top:0px;
          }
      } //.topic
      .section{
          position:relative;
          min-height:68px;
          margin-bottom:20px;
          overflow:hidden;
          .img{
              width:68px;
              height:68px;
              background:url(../img/rule.png) 0 0 no-repeat;
              &.img-2{
                  background-position:-88px 0;
              }
              &.img-3{
                  background-position:-176px 0;
              }
              &.img-4{
                  background-position:-264px 0;
              }
              &.img-5{
                  background-position:-352px 0;
              }
              &.img-6{
                  background-position:-440px 0;
              }
          }
          > h4{
              position:relative;
              margin:-68px 0 0 78px;
              font-size:15px;
              font-weight:bold;
              padding:5px 0;
          }
          .note{
              position:relative;
              margin:0 0 0 78px;
              line-height:18px;
          }
      }
      .annotation{
          font-size:11px;
          line-height:18px;
          margin-top:10px;
          padding-left:10px;
      }
      dl{
          dt{
              width:50px;
              margin-bottom:10px;
          }
          dd{
              margin-left:70px;
              margin-bottom:10px;
          }
      }
      ol{
          //padding:0;
          //margin:0;
          > ol{
              //padding:0;
              //margin:0 0 0 10px;
              li{
                  list-style-type:lower-roman;
              }
          }
      }
  } //.explanation
}
@media (max-width: $screen-sm-min) {
  #rule-content .head {
    height: auto;
    margin-top: 30px;
    text-align: center;
  }
}
