.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 256px;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
}

.slideout-menu-left {
  left: 0;
}

.slideout-menu-right {
  right: 0;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  will-change: transform;
  background-color: #FFF; /* A background-color is required */
  min-height: 100vh;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

#mobile-sidebar {
  display: none;
  background-color: #333;
  .main-menu {
    position: relative;
    margin: 0;
    padding: 60px 0 0 0;
    li {
      padding: 0;
      // line-height: 40px;
      border-top: #888 1px solid;
      &.group {
        >a::after {
          content: ">";
          float: right;
        }
      }
      a {
        display: block;
        line-height: 1.5em;
        padding: 15px;
        color:#fff;
      }
      ul.submenu {
        position: absolute;
        width: 100%;
        top:0;
        padding: 0;
        display: none;
      }
    }
    .search {
      padding: 15px 10px;
      input {
        display: inline-block;
        width:180px;
      }
      button {
        display: inline-block;
        width:50px;
      }
    }
    .translate {
      padding: 5px 10px;
      background-color: #ccc;
      a.goog-logo-link {
        display: inline;
      }
    }
  }
}