#product-content{
    margin-bottom:40px;
    .head{
        position:relative;
        margin-top:100px;
        height:55px;
        border-bottom:$light 1px solid;
        .title{
            float:left;
            color:$dark;
            font-size:36px;
            @media (max-width: $screen-xs-min) {
                float:none;
            }
        }
        .breadcrumb{
            @media (max-width: $screen-xs-min) {
                float:none;
                height:30px;
                line-height:30px;
                margin:0;
                padding:0;
            }
        }
        @media (max-width: $screen-xs-min) {
            height:auto;
            margin-top: 150px;
        }
    }
    .photo{
        @include make-xs-column(12);
        @include make-sm-column(6);
        
        margin-top:30px;
        .frame{
            position:relative;
            width:auto;
            max-width:400px;
            overflow:hidden;
            background-color:$lighter;
            padding:10px;
            border:$light 1px solid;
        }
        .thumbs{
            margin-top: 10px;
            .thumb-item{
                cursor:pointer;
            }
        }
    }
    .product-info{
        @include make-xs-column(12);
        @include make-sm-column(6);
        
        margin-top:30px;
        color:$darker;
        .product-name{
            font-size:24px;
            padding-bottom:5px;
            border-bottom:$light 1px solid;
        }
        .promo{
            padding:10px 0;
            font-size:18px;
            color:$highlight;
            border-bottom:$light 1px solid;
        }
        .feature{
            padding: 10px 0;
            ul {
                padding:10px 0 0 20px;
                li{
                    font-size:13px;
                }
            }
        }
        .gift{
            font-size:13px;
        }
        .spec{
            label{
                margin-right: 10px;
            }
            .input-group{
                display:inline-block;
                width:180px;
                height:38px;
                vertical-align:middle;
                background:url(../img/sort-icon.png) 144px 3px no-repeat;
                @include border-left-radius(20px);
                @include border-right-radius(20px);
                border:$light 1px solid;
                overflow:hidden;
                .form-control{
                    width:50px;
                    height:38px;
                    padding: 5px 8px;
                    width: 130%;
                    border:0 none;
                    box-shadow: none;
                    border-radius: 0;
                    background: transparent;
                    background-image: none;
                    -webkit-appearance: none;
                    &:focus{
                        outline:none;
                    }
                    option{
                        border:0 none;
                        background: transparent;
                        box-shadow: none;
                        padding: 5px 8px;
                    }
                }
            } // .input-group

        }
        .buy{
            position:relative;
            overflow:hidden;
            text-align:left;
            margin-top:20px;
            padding-bottom:10px;
            border-bottom:$light 1px solid;
            .price{
                @include make-xs-column(6);
                line-height:28px;
                padding:0;
                border-right:$light 1px solid;
                .discount{
                    line-height: 36px;
                    font-size: 22px;
                    color:$highlight;
                }
                .sale{
                    font-size:36px;
                    color:$highlight;
                }
            }
            .select{
                @include make-xs-column(6);
                padding:15px 10px;
                .input-group{
                    display:inline-block;
                    width:120px;
                    height:38px;
                    vertical-align:middle;
                    background:url(../img/sort-icon.png) 84px 3px no-repeat;
                    @include border-left-radius(20px);
                    @include border-right-radius(20px);
                    border:$light 1px solid;
                    overflow:hidden;
                    .form-control{
                        width:50px;
                        height:38px;
                        padding: 5px 8px;
                        width: 130%;
                        border:0 none;
                        box-shadow: none;
                        border-radius: 0;
                        background: transparent;
                        background-image: none;
                        -webkit-appearance: none;
                        &:focus{
                            outline:none;
                        }
                        option{
                            border:0 none;
                            background: transparent;
                            box-shadow: none;
                            padding: 5px 8px;
                        }
                    }
                } // .input-group
            } //.select
        } //.buy
        .control-btns{
          margin-top:20px;
          .btn-reset{
            width:150px;
            height:38px;
            border-radius:36px;
            background-color:$highlight;
            color:$lightest;
            font-size:15px;
            font-weight:bold;
            margin-right:10px;
            text-align:left;
            .icon{
              display:inline-block;
              vertical-align:middle;
              width:24px;
              height:20px;
              margin-left:15px;
              background:url(../img/icons.png) -298px -8px no-repeat;
            }
            &:active {
              background-color: darken($color: $highlight, $amount: 8);
              line-height: 1.8em;
            }
          }
            .btn-submit{
                width:150px;
                height:38px;
                border-radius:36px;
                background-color:$highlight;
                color:$lightest;
                font-size:15px;
                font-weight:bold;
                text-align:left;
                .icon{
                    display:inline-block;
                    vertical-align:middle;
                    width:24px;
                    height:20px;
                    margin-left:15px;
                    background:url(../img/icons.png) -327px -9px no-repeat;
                }
                &:active {
                  background-color: darken($color: $highlight, $amount: 8);
                  line-height: 1.8em;
                }
            }
        }
    }
    .detail{
        position:relative;
        margin-top:30px;
        .control-btns{
            margin-top:20px;
            text-align: center;
            margin-bottom: 20px;
            .btn-reset{
                width:150px;
                height:38px;
                border-radius:36px;
                background-color:$highlight;
                color:$lightest;
                font-size:15px;
                font-weight:bold;
                margin-right:10px;
                text-align:left;
                .icon{
                    display:inline-block;
                    vertical-align:middle;
                    width:24px;
                    height:20px;
                    margin-left:15px;
                    background:url(../img/icons.png) -298px -8px no-repeat;
                }
                &:active {
                  background-color: darken($color: $highlight, $amount: 8);
                  line-height: 1.8em;
                }
            }
            .btn-submit{
                width:150px;
                height:38px;
                border-radius:36px;
                background-color:$highlight;
                color:$lightest;
                font-size:15px;
                font-weight:bold;
                text-align:left;
                .icon{
                    display:inline-block;
                    vertical-align:middle;
                    width:24px;
                    height:20px;
                    margin-left:15px;
                    background:url(../img/icons.png) -327px -9px no-repeat;
                }
                &:active {
                  background-color: darken($color: $highlight, $amount: 8);
                  line-height: 1.8em;
                }
            }
        }
        .nav-tabs{
            a{
                color:$dark;
            }
        }
        .nav-pills{
            li{
                &.active{
                    a{
                        background-color: #ccc;
                    }
                }
                a{
                    color:$dark;
                }
            }
        }
        .tab-content{
            padding:20px 0;
            border-bottom:$light 1px solid;
            h2{
                font-size: 30px;
            }
            h3{
                font-size:24px;
            }
            h4{
                font-size: 18px;
            }
            .tab-pane{
                overflow: hidden;
            }
            @media (max-width: $screen-sm-min) {
                img{
                    max-width: 100%;
                    height: auto !important;
                }
            }
        }
    }
    .shop-plus{
        @include make-xs-column(12);
        // background-color: #F3F3F3;
        // margin: 10px 0;
        // border-top: #E8E8E8 1px solid;
        // border-bottom: #E8E8E8 1px solid;
        padding-top: 10px;
        padding-bottom: 10px;
        //padding: 10px 0;
        header{
            width:100%;
            line-height:36px;
            height:36px;
            margin-top: 10px;
            margin-bottom: 10px;
            @include make-row();
            .title{
                @include make-xs-column(12);
                background:url(../img/headers.png) 0px -200px no-repeat;
                text-indent:30px;
                font-size:24px;
            }
        }
        .filter{
            text-align: center;
            margin-bottom: 5px;
            strong{
                color:$highlight;
                font-size: 1.2em;
            }
            //
            //margin-top: 10px;
        }
        .dollor{
            color:$highlight;
            font-size: 1.2em;
        }
        @media (max-width: $screen-xs-min) {
            .filter{
                margin-top: 20px;
            }
            .row{
                div{
                    text-align: center;
                }
            }
            .col-xs-12{
                padding-bottom: 10px;
                border-bottom: #ccc 1px solid;
            }
        }
    }
    .suggestion{
        position:relative;
        margin-top:30px;
        header{
            width:100%;
            line-height:36px;
            height:36px;
            .title{
                @include make-xs-column(12);
                background:url(../img/headers.png) 0px -200px no-repeat;
                text-indent:30px;
                font-size:24px;
            }
            .control{
                @include make-xs-column(8);
                .prev{
                    position:absolute;
                    right:40px;
                    width:33px;
                    height:33px;
                    background:url(../img/btns.png) 0 -8px no-repeat;
                    &:hover{
                        background-position:-100px -8px;
                        cursor:pointer;
                    }
                }
                .next{
                    position:absolute;
                    right:0;
                    width:33px;
                    height:33px;
                    background:url(../img/btns.png) -41px -8px no-repeat;
                    &:hover{
                        background-position:-148px -8px;
                        cursor:pointer;
                    }
                }
            }
        }
        .products{
            @include make-row();
            margin-top:10px;
            .item{
                @include make-xs-column(12);
                @include make-sm-column(6);
                @include make-md-column(4);
                .box-top{
                    width:100%;
                    padding:8px;
                    color:$darker;
                    border-bottom:$light 1px solid;
                    //.border-top-radius(6px);
                    //background-color:$lighter;
                    .name{
                        font-weight:bold;
                        font-size:15px;
                        line-height:1.2em;
                        text-align:center;
                        padding:5px 0 3px;
                        height: 50px;
                    }
                }
                .box-bottom{
                    width:100%;
                    //.border-bottom-radius(6px);
                    //border:$light 1px solid;
                    //border-top:0 none;
                    //background-color:$lighter;
                    height:40px;
                    .price{
                        float:left;
                        line-height:40px;
                        text-decoration:line-through;
                        padding-left:10px;
                    }
                    .sprice{
                        float:right;
                        line-height:40px;
                        color:$highlight;
                        font-size:20px;
                        font-weight:bold;
                        padding-right:10px;
                        //font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, sans-serif;
                        small{
                            font-size:13px;
                        }
                    }
                    .like{
                        float:right;
                        width:40px;
                        height:40px;
                        border-left:$light 1px solid;
                        background:url(../img/product-btns.png) -5px -5px no-repeat;
                        cursor:pointer;
                        &:hover{
                            background-position:-105px -5px;
                        }
                    } // .like
                    .buy{
                        float:right;
                        width:40px;
                        height:40px;
                        border-left:$light 1px solid;
                        background:url(../img/product-btns.png) -55px -5px no-repeat;
                        cursor:pointer;
                        &:hover{
                            background-position:-155px -5px;
                        }
                    }
                    .nis{
                        padding: 10px;
                        text-align: center;
                    }
                }
            } //.item
        } //.products
    }
}
.product-info {
    .prices {
        border-top:#eee 1px solid;
        border-bottom:#eee 1px solid;
        padding: 5px 0;
        margin: 5px 0;
        .line {
            width: 100%;
            height: 2px;
            border-top:#eeeeee 1px dashed;
        }
        .range {
            padding: 5px 0;
            .price {
                strong {
                    font-size: 18px;
                    color: #c00;
                }
            }
        }
    }
    .select-preview {
        color: #888;
        .amount {
            color: rgb(39, 39, 39);
        }
        .subtotal {
            font-size: 16px;
            color: #c00;
        }
    }
}

@media (max-width: $screen-sm-min) {
  #product-content {
    .head {
      height: auto;
      margin-top: 30px;
      text-align: center;
    }
    .detail, .product-info {
      .control-btns{
        .btn-reset {
          width: 100%;
        }
        .btn-submit {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }

    
  }
  