#store-content{
  margin-bottom:40px;
  min-height:750px;
  .head{
      position:relative;
      margin-top:100px;
      height:55px;
      border-bottom:$light 1px solid;
      .title{
          float:left;
          color:$dark;
          font-size:36px;
          @media (max-width: $screen-xs-min) {
              float:none;
          }
      }
      .breadcrumb{
          @media (max-width: $screen-xs-min) {
              float:none;
              height:30px;
              line-height:30px;
              margin:0;
              padding:0;
          }
      }
      @media (max-width: $screen-xs-max) {
          height:auto;
          margin-top:230px;
          overflow:hidden;
      }
  } //.head
  span.icon{
      float:left;
      width:24px;
      height:36px;
      background:url(../img/icons.png) -260px 0 no-repeat;
  }
  .main-store{
      margin-top:30px;
      > .row{
          margin-bottom:30px;
          .store-name{
              position:relative;
              line-height:36px;
              color:$highlight;
          }
          ul{
              padding:0 0 0 22px;
              li{
                  list-style-type:none;
                  color:$dark;
              }
          }
      }
      img{
          padding:10px;
          background-color:$lighter;
      }
  }
  .agency{
      position:relative;
      line-height:30px;
      padding:10px 0;
      color:$darker;
      .agency-name{
          position:relative;
          line-height:36px;
          color:$highlight;
      }
      .store-name{
          border:$light 1px solid;
          border-bottom:0 none;
          background-color:$lighter;
          line-height:35px;
          font-size:16px;
          text-align:center;
      }
      .store-info{
          border:$light 1px solid;
          border-top:0 none;
          color:$dark;
          padding:5px 25px;
          margin-bottom:20px;
          li{
              line-height:25px;
          }
      }
  }
}
@media (max-width: $screen-sm-min) {
  #store-content .head {
    height: auto;
    margin-top: 30px;
    text-align: center;
  }
}
