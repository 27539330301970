.pagination {
  display: inline-block;
  padding: 10px 0;
  margin: $line-height-computed 0;
  border-top:$darkest 1px solid;
  border-bottom:$darkest 1px solid;
  width:100%;
  text-align:center;
  > li {
      display: inline-block;
      > a,
      > span {
          position: relative;
          float: left; // Collapse white-space
          padding: $padding-base-vertical $padding-base-horizontal;
          line-height: $line-height-base;
          text-decoration: none;
          color: $darkest;
          margin-left: -1px;
      }
      &:first-child {
          > a,
          > span {
              margin-left: 0;
          }
      }
      &.prev{
          > a{
              height:32px;
              background:url(../img/btns.png) -370px center no-repeat;
          }
      }
      &.next{
          > a{
              height:32px;
              background:url(../img/btns.png) -408px center no-repeat;
          }
      }
      &.divider{
          display: inline-block;
          height:12px;
          margin-bottom:10px;
          border-left:#000 1px solid;
      }
  }

> li > a,
> li > span {
  &:hover,
  &:focus {
    color: $dark;
  }
}

> .active > a,
> .active > span {
  &,
  &:hover,
  &:focus {
    z-index: 2;
    border-radius: 60px;
    background-color:$darkest;
    color:$lightest;
    cursor: default;
  }
}

> .disabled {
  > span,
  > span:hover,
  > span:focus,
  > a,
  > a:hover,
  > a:focus {
    color: $pagination-disabled-color;
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border;
    cursor: not-allowed;
  }
}
}