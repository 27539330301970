.jumbotron{
  position:relative;
  width:100%;
  height:600px;
  overflow:hidden;
  z-index:0;
  .down{
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -80px;
      width: 161px;
      height: 57px;
      background: url(../img/down-icon.png) 0 0 no-repeat;
      z-index: 999;
  }
  .control{
      position:absolute;
      display: none;
      width:100%;
      z-index:9997;
      #slider-prev{
          position:absolute;
          width:39px;
          height:39px;
          top:300px;
          left:10px;
          background:url(../img/btns.png) -200px -5px no-repeat;
          a{
              text-indent:-9999px;
              display:block;
              width:100%;
              height:100%;
              outline:none;
          }
      }
      #slider-next{
          position:absolute;
          width:39px;
          height:39px;
          top:300px;
          right:10px;
          background:url(../img/btns.png) -252px -5px no-repeat;
          a{
              text-indent:-9999px;
              display:block;
              width:100%;
              height:100%;
              outline:none;
          }
      }
  }
  .screen{
      position:absolute;
      left:auto;
      top:0;
      right:0;
      bottom:0;
      width:100%;
      ul.slides{
          padding:0;
          li{
              width:100%;
              list-style-type:none;
              img{
                  margin:0 auto;
                  width:100%;
              }
          }
      }
  }
  @media (max-width: $screen-sm-min) {
      margin-top: 175px;
       .down{
          display: none;
       }
  }
  @media (min-width: $screen-sm-min) {
  }
  @media (min-width: $screen-md-min) {
      //height:600px;
  }
  @media (min-width: $screen-lg-min) {
      //height:700px;
  }
}