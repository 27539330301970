#category-content{
  margin-bottom:40px;
  .head{
      position:relative;
      margin-top:100px;
      height:55px;
      .title{
          float:left;
          color:$dark;
          font-size:36px;
          @media (max-width: $screen-xs-min) {
              float:none;
          }
      }
      .breadcrumb{
          @media (max-width: $screen-xs-min) {
              float:none;
              height:30px;
              line-height:30px;
              margin:0;
              padding:0;
          }
      }
      @media (max-width: $screen-xs-min) {
          height:auto;
          margin-top: 130px;
          text-align: center;
      }
  }
  .controlbar{
      position:relative;
      border-bottom:$light 1px solid;
      border-top:$light 1px solid;
      padding:14px 0;
      height:66px;
      .page-selector{
          float:left;
          height:38px;
          width:182px;
          border-right:$light 1px solid;
          .input-group{
              display:inline-block;
              width:100px;
              height:38px;
              vertical-align:middle;
              .form-control{
                  width:50px;
                  height:38px;
                  @include border-left-radius(20px);
                  border-right:0 none;
              }
              .input-group-btn{
                  .btn{
                    @include border-right-radius(20px);
                      width:36px;
                      height:38px;
                      background:url(../img/sort-icon.png) 0px 3px no-repeat;
                      
                      &:hover{
                          border-color: #cccccc;
                          border-left-color:#fff;
                      }
                  }
              } // .input-group-btn
          } // .input-group
      } // .page-selector
      .sort{
          float:left;
          height:38px;
          width:190px;
          margin-left:10px;
          .input-group{
              display:inline-block;
              width:150px;
              height:38px;
              vertical-align:middle;
              background:url(../img/sort-icon.png) 114px 3px no-repeat;
              @include border-left-radius(20px);
              @include border-right-radius(20px);
              border:$light 1px solid;
              overflow:hidden;
              .form-control{
                  width:50px;
                  height:38px;
                  padding: 5px 8px;
                  width: 130%;
                  border:0 none;
                  box-shadow: none;
                  border-radius: 0;
                  background: transparent;
                  background-image: none;
                  -webkit-appearance: none;
                  &:focus{
                      outline:none;
                  }
                  option{
                      border:0 none;
                      background: transparent;
                      box-shadow: none;
                      padding: 5px 8px;
                  }
              }
          } // .input-group
      } // .sort
  }
  .focus{
    @include make-row();
      position:relative;
      height:350px;
      margin-top:30px;
      .main-banner{
          //.make-xs-column(12);
          @include make-sm-column(12);
          @include make-md-column(9);
          padding-right:10px;
          img{
              //.img-responsive;
              float:right;
              @media (max-width: $screen-md-min) {
                  float:left;
                  margin-left:10px;
              }
          }
      }
      .side{
          //.make-xs-column(12);
          @include make-sm-column(12);
          @include make-md-column(3);
          .banner{
              width:192px;
              margin-bottom:10px;
              @media (max-width: $screen-md-min) {
                  float:left;
                  margin:10px 0 0 10px;
              }
          }
      }
  }
  .shelf{
      border-top:0 none;
  }
}
@media (max-width: $screen-sm-min) {
  #category-content .head {
    height: auto;
    margin-top: 30px;
    text-align: center;
  }
}
