#advance-menubar{
    position:relative;
    margin-top:20px;
    .logo{
        position:relative;
        width:126px;
        height:85px;
        margin:0;
        img{
            width:100%;
        }
    }
    #menu-btn{
        position:absolute;
        width:45px;
        top: 75px;
        left:205px;
        height:45px;
        border-radius:4px;
        cursor:pointer;
        .btn{
            position:relative;
            display:block;
            width:39px;
            height:39px;
            border:0 none;
            background:url(../img/btns.png) -311px -5px no-repeat;
        }
    }
    .advance-menu{
        position:relative;
        width:auto;
        max-width:175px;
        padding:0;
        margin-top:40px;
        //border-top:$darkest solid 1px;
        border-bottom:$darkest solid 1px;
        li{
            width:auto;
            max-width:175px;
            line-height:50px;
            border-top:$darkest solid 1px;
            list-style-type:none;
            text-align:left;
            font-size:16px;
            font-weight:600;
            &.action{
                //border-bottom:$darkest solid 1px;
            }
            &.current{
                height: auto;
            }
            a{
                text-decoration:none;
                color:$darkest;
                &:hover{
                    color:$highlight;
                }
            }
            .school-select{
                padding: 10px 20px 20px;
                .divider{
                    padding-top:5px;
                }
            }
            @media (max-width: $screen-xs-min) {
                line-height: 40px;
            }
        } // li

        ul.submenu{
            //display: none;
            padding:0;
            margin:0 auto;
            width:auto;
            max-width:150px;
            border-top:$darkest dotted 1px;
            li{
                &:first-child{
                    border-top:0 none;
                }
                width:auto;
                max-width:150px;
                border-top:$darkest dotted 1px;
                border-bottom:0 none;
                font-size:12px;
                text-indent:20px;
                font-weight:normal;
                &.group{
                    font-size:14px;
                    text-indent:0;
                }
            }
        } //ul.submenu
    } // .advance-menu

    @media (max-width: $screen-sm-min) {
        position: absolute;
        top:0;
        margin-top: 0;
        left:-195px;
        width:auto;
        background-color: #ccc;
        z-index: 999;
        .logo{
            display: none;
        }
    }
}
#advance-menubar {
	.advance-menu {
		.tag-menu {
            padding-left: 0;
            line-height: 30px;
            padding-bottom: 10px;
			li {
				display: inline;
				font-size: 14px;
                font-weight: normal;
                border-top:none;
                line-height: 30px;
                &:before {
                    content: ',';
                    margin: 0 5px;
                }
                &:first-child {
                    &:before {
                        content:'';
                        margin: 0;
                    }
                }
			}
		}
	}
}