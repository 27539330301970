#mobile-nav {
	position: relative;
	width: 100%;
	z-index: 10000;
  background-color: #fff;
  overflow: hidden;
  .mobile-menu, .home, .login, .logout, .member, .cart{
    float: left;
    width: 20%;
    height: 80px;
    background-color: #fff;
    color: #fff;
    text-indent: -99999px;
    a {
      display: block;
      width: 100%;
      height: 100%
    }
  }

  .mobile-menu {
    background: url(../img/mobile-menu/menu.png) center 0 no-repeat;
  }

  .home {
    background: url(../img/mobile-menu/home.png) center 0 no-repeat;
  }

  .login {
    background: url(../img/mobile-menu/login.png) center 0 no-repeat;
  }

  .logout {
    background: url(../img/mobile-menu/logout.png) center 0 no-repeat;
  }

  .member {
    background: url(../img/mobile-menu/member.png) center 0 no-repeat;
  }

  .cart {
    background: url(../img/mobile-menu/cart.png) center 0 no-repeat;
    text-indent: 0px;
    a {
      color: #fff;
    }
    .amount {
      background-color: #990000;
      color: #fff;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      text-align: center;
      line-height: 25px;
      margin: 5px 0 0 5px;
    }
  }
}

@media (max-width: $screen-sm-min) {
	#main-header {
		position: relative;
	}

	.jumbotron {
		margin-top: 20px
	}

	#advance-menubar {
		top: -90px;
		padding-left: 20px
	}

	nav#menubar .main-menu li {
		line-height: 50px
	}

	.col-sm-3 nav#menubar {
		top: -90px
	}
}