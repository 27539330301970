.shelf{
  .item{
      @include make-sm-column(4);
      @include make-xs-column(12);
      .box-top{
          width:100%;
          padding:8px;
          color:$darker;
          border-bottom:$light 1px solid;
          //.border-top-radius(6px);
          //background-color:$lighter;
          .name{
              font-weight:bold;
              font-size:15px;
              line-height:1.2em;
              text-align:center;
              padding:5px 0 3px;
              height: 2.8em;
              overflow: hidden;
          }
      }
      .box-bottom{
          width:100%;
          //.border-bottom-radius(6px);
          //border:$light 1px solid;
          //border-top:0 none;
          //background-color:$lighter;
          height:40px;
          .price{
              float:left;
              line-height:40px;
              text-decoration:line-through;
              padding-left:10px;
          }
          .sprice{
              float:right;
              line-height:40px;
              color:$highlight;
              font-size:20px;
              font-weight:bold;
              padding-right:10px;
              //font-family:Segoe, "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, sans-serif;
              small{
                  font-size:13px;
              }
          }
          .like{
              float:right;
              width:40px;
              height:40px;
              border-left:$light 1px solid;
              background:url(../img/product-btns.png) -5px -5px no-repeat;
              cursor:pointer;
              &:hover{
                  background-position:-105px -5px;
              }
          } // .like
          .buy{
              float:right;
              width:40px;
              height:40px;
              border-left:$light 1px solid;
              background:url(../img/product-btns.png) -55px -5px no-repeat;
              cursor:pointer;
              &:hover{
                  background-position:-155px -5px;
              }
          }
          .nis{
              padding: 8px;
              text-align: center;
              color:#888;
          }
      }
  } //.item
}