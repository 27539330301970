#floatline{
  position:fixed;
  bottom:100px;
  right:10px;
  width:76px;
  height:76px;
  z-index:10000;
  background:url(../img/floating-line-icon.png) 0 0 no-repeat;
  a {
      display: block;
      width: 100%;
      height: 100%;
  }
}
#floatcart{
  position:fixed;
  bottom:20px;
  right:10px;
  width:76px;
  height:74px;
  z-index:10000;
  background:url(../img/floatcart.png) 0 0 no-repeat;
  .checkout{
      position:relative;
      width:100%;
      height:25px;
      a{
          display:block;
          width:100%;
          height:100%;
      }
  } //.checkout
  .amount{
      position:relative;
      width:100%;
      padding:0 5px;
      height:24px;
      font-size:15px;
      font-weight:bold;
      text-align:center;
      a{
          color:$lightest;
      }
  } //.amount
  .gotop{
      position:relative;
      width:38px;
      height:25px;
      line-height:25px;
      margin:0 auto;
      font-size:11px;
      color:$highlight;
      cursor:pointer;
      .icon{
          width:10px;
          height:20px;
          background:url(../img/icons.png) -85px -5px no-repeat;
          vertical-align:text-bottom;
      }
  }
}