footer{
    position:relative;
    width:100%;
    padding:30px 0;
    overflow:hidden;
    background-color:$darkest;
    color:$lightest;
    font-size:0.9em;
    line-height:1.2em;
    a{
        color:$lightest;
        &:hover{
            color:$light;
        }
    }
    .copyright{
        // margin-left:50px;
        text-align: center;
        margin-top: 8px;
        address{
            display: inline;
            font-style:normal;
        }
    }
    .contact{
        // margin-left:50px;
        text-align: center;
        span {
            margin-right: 20px;
        }
        a{
            color:$lightest;
            text-decoration:none;
            &:hover{
                text-decoration:underline;
            }
        }
    } // .contact
    .menu{
        position:relative;
        overflow:hidden;
        margin-bottom:30px;
        clear:both;
        ul {
            padding: 0;
            margin: 15px 0 0 0;
            text-align: center;
            li {
                list-style-type: none;
                margin-bottom: 5px;
            }
        }
        a{
            display: block;
            position: relative;
            width: 100%;
            text-align: center;
        }
        .icon {
            width: 50px;
            height: 50px;
            position: relative;
            margin: 0 auto;
            background: url(../img/foot-icons.png) 0 0 no-repeat;
            background-size: cover;
            &.line {
                background-position: 0 -70px;
            }
            &.delivery {
                background-position: 0 -140px;
            }
            &.store {
                background-position: 0 -210px;
            }
            &.try {
                background-position: 0 -280px;
            }
            &.blog {
                background-position: 0 -350px;
            }
        }
        h5 {
            margin-top: 0;
            text-align: center;
        }
    } // .menu
    .epaper{
        float:left;
        .icon{
            display:inline-block;
            float:left;
            width:38px;
            height:38px;
            background:url(../img/icon-mail.png) 0 0 no-repeat;
            @media (max-width: $screen-sm-min) {
                float: none;
                vertical-align: middle;
            }
        }
        label{
            display:inline-block;
            float:left;
            margin-left:10px;
            height:38px;
            line-height:38px;
            font-size:1em;
            @media (max-width: $screen-sm-min) {
                float: none;
            }
        }
        .input-box{
            float:left;
            padding:5px 10px;
            margin-left:10px;
            background-color:#fff;
            @include border-top-radius(18px);
            @include border-bottom-radius(18px);
            input{
                color:$dark;
                line-height:18px;
                float:left;
                border:0px none;
                @media (max-width: $screen-sm-min) {
                    width: 80%;
                    float:none;
                    display: inline-block;
                }
            }
            button{
                float:rihgt;
                border:0px none;
                width:22px;
                height:22px;
                background:url(../img/input-btn.gif) 0 0 no-repeat;
                @media (max-width: $screen-sm-min) {
                    float:none;
                    display: inline-block;
                }
            }
            @media (max-width: $screen-sm-min) {
                float: none;
                width: 95%;
                margin: 10px 0;
            }
        } //.input-box
        @media (max-width: $screen-sm-min) {
            float: none;
            width: 100%;
            text-align: center;
            overflow: hidden;
            margin-bottom: 10px;
        }
    } //.epaper
    .social{
        float:left;
        .share-btns{
            margin:0 0 0 17px;
            padding:0;
            li{
                list-style-type:none;
                float:left;
                width:38px;
                height:38px;
                margin-left:13px;
                background:url(../img/social-icons.png) 0 0 no-repeat;
                cursor:pointer;
                &.fb{
                    background-position:-51px 0;
                    a{
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
                &.gp{
                    background-position:-102px 0;
                }
                @media (max-width: $screen-sm-min){
                    margin: 0;
                    float: none;
                    display: inline-block;
                }
            } //li
        } //.share-btns
        @media (max-width: $screen-sm-min){
            margin: 0;
            text-align: center;
        }
        @media (max-width: $screen-sm-min){
            float: none;
        }
    } // .social
    @media (max-width: $screen-sm-min) {
        padding-bottom: 80px;
        @include center-block();
    }
}
footer {
    position: relative;
    width: 100%;
    padding: 30px 0;
    overflow: hidden;
    background-color: #000;
    color: #fff;
    font-size: .9em;
    line-height: 1.2em;
    margin-top: 20px;
}

footer a {
    color: #fff
}

footer a:hover {
    color: #ccc
}

footer .copyright {
    text-align: center;
    margin-top: 8px
}

footer .copyright address {
    display: inline;
    font-style: normal
}

footer .contact {
    text-align: center
}

footer .contact span {
    margin-right: 20px
}

footer .contact a {
    color: #fff;
    text-decoration: none
}

footer .contact a:hover {
    text-decoration: underline
}

footer .menu {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    clear: both
}

footer .menu ul {
    padding: 0;
    margin: 15px 0 0 0;
    text-align: center
}

footer .menu ul li {
    list-style-type: none;
    margin-bottom: 5px
}

footer .menu a {
    display: block;
    position: relative;
    width: 100%;
    text-align: center
}

footer .menu .icon {
    width: 50px;
    height: 50px;
    position: relative;
    margin: 0 auto;
    background: url(../img/foot-icons.png) 0 0 no-repeat;
    background-size: cover
}

footer .menu .icon.line {
    background-position: 0 -70px
}

footer .menu .icon.delivery {
    background-position: 0 -140px
}

footer .menu .icon.store {
    background-position: 0 -210px
}

footer .menu .icon.try {
    background-position: 0 -280px
}

footer .menu .icon.blog {
    background-position: 0 -350px
}

footer .menu h5 {
    margin-top: 0;
    text-align: center
}

footer .epaper {
    float: left
}

footer .epaper .icon {
    display: inline-block;
    float: left;
    width: 38px;
    height: 38px;
    background: url(../img/icon-mail.png) 0 0 no-repeat
}

@media (max-width: 768px) {
    footer .epaper .icon {
        float:none;
        vertical-align: middle
    }
}

footer .epaper label {
    display: inline-block;
    float: left;
    margin-left: 10px;
    height: 38px;
    line-height: 38px;
    font-size: 1em
}

@media (max-width: 768px) {
    footer .epaper label {
        float:none
    }
}

footer .epaper .input-box {
    float: left;
    padding: 5px 10px;
    margin-left: 10px;
    background-color: #fff;
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px
}

footer .epaper .input-box input {
    color: #666;
    line-height: 18px;
    float: left;
    border: 0 none
}

@media (max-width: 768px) {
    footer .epaper .input-box input {
        width:80%;
        float: none;
        display: inline-block
    }
}

footer .epaper .input-box button {
    float: rihgt;
    border: 0 none;
    width: 22px;
    height: 22px;
    background: url(../img/input-btn.gif) 0 0 no-repeat
}

@media (max-width: 768px) {
    footer .epaper .input-box button {
        float:none;
        display: inline-block
    }
}

@media (max-width: 768px) {
    footer .epaper .input-box {
        float:none;
        width: 95%;
        margin: 10px 0
    }
}

@media (max-width: 768px) {
    footer .epaper {
        float:none;
        width: 100%;
        text-align: center;
        overflow: hidden;
        margin-bottom: 10px
    }
}

footer .social {
    float: left
}

footer .social .share-btns {
    margin: 0 0 0 17px;
    padding: 0
}

footer .social .share-btns li {
    list-style-type: none;
    float: left;
    width: 38px;
    height: 38px;
    margin-left: 13px;
    background: url(../img/social-icons.png) 0 0 no-repeat;
    cursor: pointer
}

footer .social .share-btns li.fb {
    background-position: -51px 0
}

footer .social .share-btns li.fb a {
    display: block;
    width: 100%;
    height: 100%
}

footer .social .share-btns li.gp {
    background-position: -102px 0
}

@media (max-width: 768px) {
    footer .social .share-btns li {
        margin:0;
        float: none;
        display: inline-block;
    }
    footer {
        .logo {
            margin-bottom: 20px;
            text-align: center;
            img {
                margin: 0 auto;
            }
        }
        .col-xs-6 {
            margin-bottom: 20px;
        }
    }
}

@media (max-width: 768px) {
    footer .social {
        margin:0;
        
    }
}

@media (max-width: 768px) {
    footer .social {
        float:none
    }
}

@media (max-width: 768px) {
    footer {
        padding-bottom:80px;
        display: block;
        margin-left: auto;
        margin-right: auto
    }
}