#qa-content{
  margin-bottom:40px;
  min-height:1400px;
  .head{
      position:relative;
      margin-top:100px;
      height:55px;
      border-bottom:$light 1px solid;
      .title{
          float:left;
          color:$dark;
          font-size:36px;
          @media (max-width: $screen-xs-min) {
              float:none;
          }
      }
      .breadcrumb{
          @media (max-width: $screen-xs-min) {
              float:none;
              height:30px;
              line-height:30px;
              margin:0;
              padding:0;
          }
      }
      @media (max-width: $screen-xs-max) {
          height:auto;
          margin-top:230px;
          overflow:hidden;
      }
  } //.head
  
  .explanation{
      position:relative;
      line-height:30px;
      padding:20px 0;
      color:$darker;
      .topic{
          position:relative;
          //margin-top:20px;
          height:40px;
          line-height:40px;
          color:$highlight;
          font-size:15px;
          font-weight:bold;
          .icon{
              float:left;
              width:24px;
              height:40px;
              background:url(../img/icons.png) -113px 5px no-repeat;
          }
      } //.topic
      .tel{
          font-size:30px;
          color:$highlight;
      }
  } //.explanation
  .bg{
      width:100%;
      height:500px;
      background:url(../img/qa-bg.jpg) right bottom no-repeat;
  }
}
@media (max-width: $screen-sm-min) {
  #qa-content {
    .head {
      height: auto;
      margin-top: 30px;
      text-align: center;
    }
    
    .explanation {
      .topic {
        height: auto;
        line-height: 30px;
        .icon {
          height: 30px;
          background-position: -113px 0;
        }
      }
    }

    .bg {
      background-size: contain;
      height: 300px;
    }
  }
}
