#school-content{
  margin-bottom:40px;
  min-height: 600px;
  .head{
      position:relative;
      margin-top:100px;
      height:55px;
      border-bottom:$light 1px solid;
      .title{
          float:left;
          color:$dark;
          font-size:36px;
          @media (max-width: $screen-xs-min) {
              float:none;
          }
      }
      .breadcrumb{
          @media (max-width: $screen-xs-min) {
              float:none;
              height:30px;
              line-height:30px;
              margin:0;
              padding:0;
          }
      }
      @media (max-width: $screen-xs-max) {
          height:auto;
          margin-top:230px;
          overflow:hidden;
      }
  } //.head
  
  .questions{
      position:relative;
      padding-top:10px;
      .item-list{
          padding:0;
          margin:20px 0 0 0;
          li{
              list-style-type:none;
              height:55px;
              line-height:30px;
              border-top:$light 1px dotted;
              &:hover{
                  a ,.number{
                      color: $highlight;
                      text-decoration:none;
                  }
              }
              &:first-child{
                  border-top:0 none;
              }
              .number{
                  font-family:Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
                  display:inline-block;
                  width:55px;
                  color:$dark;
                  font-size:24px;
                  color:$light; 
                  strong{
                      font-size:30px;
                      font-weight:normal;
                  }
              }
              a{
                  font-size:18px;
                  color:$dark;
              }
          }
      }
      .content{
          position:relative;
          margin-top:20px;
          font-size:15px;
          line-height:28px;
          overflow: hidden;
      }
      .container{
          width: auto;
          .item{
            @include make-xs-column(4);
          }
      }
      .suggest{
          text-indent: -10000px;
          background: url(../img/suggest.png) 0 0 no-repeat;
          width: 100%;
          height: 100px;
          border-bottom: #ccc dotted 1px;
          //border-style:;
      }
  } //.questions
  
  .article{
      position:relative;
      margin-top:30px;
      .topic{
          position:relative;
          font-size:28px;
          color: $highlight;
      }
      .date{
          position:relative;
          font-size:12px;
          height:20px;
          color:$dark;
      }
      .content{
          position:relative;
          margin-top:20px;
          font-size:15px;
          line-height:28px;
          overflow: hidden;
      }
  }
  ul.pager{
          display: inline-block;
          padding: 10px 0;
          margin: $line-height-computed 0;
          border-top:$dark 1px solid;
          border-bottom: $dark 1px solid;
          width:100%;
          text-align:center;
          > li {
              display: inline-block;
              > a,
              > span {
                  position: relative;
                  float: left; // Collapse white-space
                  padding: $padding-base-vertical $padding-base-horizontal;
                  line-height: $line-height-base;
                  text-decoration: none;
                  color: $dark;
                  margin-left: -1px;
              }
              &:first-child {
                  > a,
                  > span {
                      margin-left: 0;
                  }
              }
              &.prev{
                  > a{
                      height:32px;
                      .icon{
                          display:inline-block;
                          height:20px;
                          width:26px;
                          background:url(../img/btns.png) -370px -17px no-repeat;
                          vertical-align:middle;
                      }
                  }
              }
              &.next{
                  > a{
                      height:32px;
                      .icon{
                          display:inline-block;
                          height:20px;
                          width:26px;
                          margin-left:10px;
                          background:url(../img/btns.png) -408px -17px no-repeat;
                          vertical-align:middle;
                      }
                  }
              }
              &.divider{
                  display: inline-block;
                  height:12px;
                  margin-bottom:10px;
                  border-left:#000 1px solid;
              }
          }
      
        > li > a,
        > li > span {
          &:hover,
          &:focus {
            color: $dark;
          }
        }
      
        > .active > a,
        > .active > span {
          &,
          &:hover,
          &:focus {
            z-index: 2;
            border-radius: 60px;
            background-color:$darkest;
            color:$lightest;
            cursor: default;
          }
        }
      
        > .disabled {
          > span,
          > span:hover,
          > span:focus,
          > a,
          > a:hover,
          > a:focus {
            color: $pagination-disabled-color;
            background-color: $pagination-disabled-bg;
            border-color: $pagination-disabled-border;
            cursor: not-allowed;
          }
        }
  }
}

@media (max-width: $screen-sm-min) {
  #school-content .head {
    height: auto;
    margin-top: 30px;
    text-align: center;
  }
}
