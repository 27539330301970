.product-box {
	@include item-box-shadow;
	.box-top{
		border-bottom: $border-color 1px solid;
	}
	.box-bottom {
		padding-top: 30px;
		padding-bottom: 20px;
		overflow: hidden;
		background-color: #fff;
		.box-left {
			padding-right: 10px;
		}
		.box-right {
			text-align: right;
		}
		.name {
			font-size: 15px;
			height: 36px;
			line-height: 18px;
			overflow: hidden;
		}
		.price {
			margin-top: 13px;
			font-weight: bolder;
			font-size: 12px;
			color: #cc0000;
		}
		.sprice {
			color: #cc0000;
			font-weight: bolder;
			letter-spacing: -1px;
			font-size: 28px;
			line-height: 66px;
		}
	}
	&.advice {
		.box-top {
			background: url(../img/advice.gif) right 0 no-repeat;	
		}
	}
	&.favourable {
		.box-top {
			background: url(../img/favourable.gif) right 0 no-repeat;	
		}
	}
}