#shop-process{margin:0;width: 100%;clear:both}
#shop-process table{width: 100%;background:#fff;}
#shop-process th{background:#cc0000; padding:12px 6px 13px; text-align:center;
color:#FFF; border-right:1px solid #fff;}
#shop-process th.last{border-right:none}
#shop-process td.first{border-left:1px solid #ccc;}
#shop-process td.last{border-right:1px solid #ccc;}
#shop-process td{padding:8px 6px 9px;border-right:1px solid #ccc;text-align:center;}
#shop-process td.text-alignleft{text-align:left;}
#shop-process td.first img{ border:1px solid #efefef; width:80px;}
#shop-total {
  ul{ 
    list-style-type:none;
    li {
      text-align:right;
      span {
        color:#C00; font-size:18px; padding:0px 3px;
      }
    }
  }
}
#shop-process td span{ color:#C00; font-size:18px; padding:0px 3px;}
#shop-process .control-btns {margin-top: 20px;padding:20px 0 0;clear:both; border-top:1px dotted #ccc;}
#shop-process .control-btns .btn-reset {
  width: 157px;
  height: 47px;
  border-radius: 36px;
  background-color: #ff9900;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  margin-right: 10px;
}
#shop-process .control-btns .btn-submit {
  width: 157px;
  height: 47px;
  border-radius: 36px;
  background-color: #cc0000;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
}

#shop-process {
  .same-to-payer {
    font-size: 18px;
    margin-top: 10px;
  }

  .d-md-mt-10 {
    @media (max-width: $screen-md-min) {
      margin-top: 10px;
    }
  }
  .region-notice {
    background-color: #feffc6;
    padding: 20px;
    margin-top: 10px;
    font-size: 16px;
    line-height: 1.8em;
  }
}

.zebra td, .zebra th {
    padding: 10px;
    border-bottom: 1px solid #ccc;    
}

.zebra tbody tr:nth-child(even) {
    background: #f5f5f5;
    -webkit-box-shadow: 0 1px 0 rgba(255,255,255,.8) inset; 
    -moz-box-shadow:0 1px 0 rgba(255,255,255,.8) inset;  
    box-shadow: 0 1px 0 rgba(255,255,255,.8) inset;        
}

.zebra th {
    text-align: left;
    text-shadow: 0 1px 0 rgba(255,255,255,.5); 
    border-bottom: 1px solid #ccc;
    background-color: #eee;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f5f5f5), to(#eee));
    background-image: -webkit-linear-gradient(top, #f5f5f5, #eee);
    background-image:    -moz-linear-gradient(top, #f5f5f5, #eee);
    background-image:     -ms-linear-gradient(top, #f5f5f5, #eee);
    background-image:      -o-linear-gradient(top, #f5f5f5, #eee); 
    background-image:         linear-gradient(top, #f5f5f5, #eee);
}

.zebra th:first-child {
    -moz-border-radius: 6px 0 0 0;
    -webkit-border-radius: 6px 0 0 0;
    border-radius: 6px 0 0 0;  
}

.zebra th:last-child {
    -moz-border-radius: 0 6px 0 0;
    -webkit-border-radius: 0 6px 0 0;
    border-radius: 0 6px 0 0;
}

.zebra th:only-child{
    -moz-border-radius: 6px 6px 0 0;
    -webkit-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0;
}

.region-selector {
  margin-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px dotted #ccc;
  h2{ 
    font-size:22px; 
    background:url(../img/icon-bone-r.png) no-repeat left 5px;
    padding:0 0 8px 20px;
  }
  label.radio-inline {
    margin-left: 0;
    margin-right: 15px;
    font-size: 16px;
  }
}

#shop-plus{padding:20px 0 0 0px; clear:both}
#shop-plus h2{ font-size:18px; background:url(../img/icon-bone-r.png) no-repeat left top; padding:0 0 8px 18px; border-bottom:1px dotted #ccc;}
#shop-plus h2 span{ font-size:12px; padding-left:10px;}
#shop-plus dl{ margin:0 0 10px; padding:0; float:left; width:50%; height:100px;}
#shop-plus dt{margin:0; padding:0;float:left;}
#shop-plus dt img{ margin:0px 3px; vertical-align:middle; width:80px;}
#shop-plus dd{ margin:0; padding:20px 0 0 5px;}
#shop-plus dd p{margin:0; padding:0; width:95%}
#shop-plus dd p span{color:#C00; font-size:22px;}
#shop-plus span.dollor{
    font-size:20px;
    font-family:"Calibri", Arial;
    color:$highlight;
}

#shop-gift{padding:20px 0 0 0px;clear:both}
#shop-gift h2{ font-size:18px; background:url(../img/icon-bone-y.png) no-repeat left top; padding:0 0 8px 18px; border-bottom:1px dotted #ccc;}
#shop-gift dl{ margin:0 0 10px; padding:0;}
#shop-gift dt{margin:0; padding:0;float:left;}
#shop-gift dt img{ margin:0px 3px; vertical-align:middle; width:80px;}
#shop-gift dd{ margin:0; padding:20px 0 0 100px;}
#shop-gift dd p{margin:0; }
#shop-gift dd p span{color:#C00; font-size:22px;}
#shop-gift dd p span.dollor{font-size:14px; font-family:"Calibri", Arial}

#shop-payment{
  margin-top: 20px;
  padding:0 0 10px 0;
  clear:both;
  border-bottom: 1px dotted #ccc;
  h2{
    font-size: 22px;
    background: url(../img/icon-bone-r.png) no-repeat left 5px;
    padding: 0 0 8px 20px;
  }
  .payment {
    padding-bottom: 8px;
    &:hover {
      background-color: #e6e6e6;
    }
    label {
      font-size: 16px;
      line-height: 30px;
      cursor: pointer;
      input {
        margin-right: 3px;
        // font-size: 14px;
        // line-height: 14px;
        // vertical-align: text-top;
      }
      img {
        margin-left: 10px;
      }
    }
    .notice {
      margin: 5px 0 0 18px;
      color: #666;
    }
  }
  dl{
    margin:0 0 10px;
    padding:0;
  }
  dt{
    margin:0 0 5px;
    padding:0;
    span {margin:0; padding:0 6px; color:#C00}
    img{ margin:0px 3px; vertical-align:middle;}
  }
  dd{
    margin:0 0 25px;
    padding:0 0 5px;
    p {
      margin:0;
      span {
        color:#C00;
        font-size:22px;
        &.dollor {
          font-size:14px;
          font-family:"Calibri", Arial
        }
      }
    }
  }
}

#shop-orderone{
  padding:10px 0 20px 0;
  clear:both;
  border-bottom: 1px dotted #ccc;
  h2 {
    font-size: 22px;
    background: url(../img/icon-bone-r.png) no-repeat left 5px;
    padding: 0 0 8px 20px;
  }
  #register-notice{
    color: #a3a3a3;
  }
}
#shop-address{
  padding:20px 0 0 0px;clear:both;
  h2 {
    font-size: 22px;
    background:url(../img/icon-bone-r.png) no-repeat left 5px;
    padding:0 0 8px 18px;
    #same-to-orderone {
      margin-left: 10px;
      cursor: pointer;
    }
    label {
      cursor: pointer;
    }
  }
}

#checkoutForm {
  .error {
    color: #cc0000;
  }
}

#shop-process{
    label{
        margin-top:10px;
    }
    .input-title{
        @include make-xs-column(12);
    }
}

#other-plus{padding:20px 0 0 0px; clear:both}
#other-plus h2{ font-size:18px; background:url(../img/icon-bone-r.png) no-repeat left center; padding:8px 0 8px 18px; border-bottom:1px dotted #ccc;border-top:1px dotted #ccc;}
#other-plus h2 span{ font-size:12px; padding-left:10px;}
#other-plus img{ margin:5px 0px;}
#other-plus p{margin:0; padding:0; width:95%}
#other-plus p span{color:#C00; font-size:22px;}
#other-plus span.dollor{
    font-size:20px;
    font-family:"Calibri", Arial;
    color:$highlight;
}
#other-plus{
  .filter{
    strong{
      font-size: 18px;
      color:$highlight;
    }
  }
}

#statusPanel {
  .col-wrapper {
    padding: 0 20px;
    h4 {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: rgb(228, 228, 228) 1px solid;
    }
    .lost-pw {
      margin-top: 10px;
    }
  }
  .row.bottom {
    margin-top: 20px;
  }
  #fb-login {
    line-height: 40px;
    padding: 10px 0;
    img {
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}
@media (max-width: $screen-sm-min) {
  input[name=coupon_number] {
    margin: 5px 0;
  }
  #coupon {
    padding-bottom: 0px !important;
  }
  #member-content .cart-info .control-btns {
    button {
      width: 150px !important;
    }
  }
  #shop-process .control-btns {
    button {
      width: 150px !important;
    }
  }
}