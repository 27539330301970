@import "components/normalize";
@import "bootstrap";
@import "mixin";
@import "components/grid-extra";

$lightest:#fff;
$lighter:#efefef;
$light:#ccc;
$darkest:#000;
$darker:#333333;
$dark:#666;
$highlight:#cc0000;
$border-color:#f3f0eb;
$font-family-sans-serif:  "微軟正黑體", "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif, "STHeiti";

@import "components/common";
@import "components/dropdown-submenu";
@import "components/product-box";
@import "components/floatcart";
@import "components/foot";
@import "components/main-header";
@import "components/main-menu";
@import "components/jumbotron";
@import "components/advance-menu";
@import "components/shelf";
@import "components/breadcrumb";
@import "components/pagination";
@import "components/mobile";
@import "components/mobile-sidebar";

// page
@import "pages/index";
@import "pages/category";
@import "pages/member";
@import "pages/cart";
@import "pages/product";
@import "pages/rule";
@import "pages/qa";
@import "pages/store";
@import "pages/brand";
@import "pages/school";
@import "pages/privacy";
@import "pages/events";
@import "pages/grade";