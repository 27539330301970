body {
    background-color: #fff;
}
#grade-content{
    margin-bottom:40px;
    min-height:750px;
    .head{
        position:relative;
        margin-top:100px;
        height:55px;
        border-bottom:$light 1px solid;
        .title{
            float:left;
            color:$dark;
            font-size:36px;
            @media (max-width: $screen-xs-min) {
                float:none;
            }
        }
        .breadcrumb{
            @media (max-width: $screen-xs-min) {
                float:none;
                height:30px;
                line-height:30px;
                margin:0;
                padding:0;
            }
        }
        @media (max-width: $screen-xs-max) {
            height:auto;
            margin-top:230px;
            overflow:hidden;
        }
    } //.head
    
    .explanation{
        position:relative;
        line-height:30px;
        padding:20px 0;
        color:$darker;
        .topic{
            position:relative;
            margin-top:20px;
            height:40px;
            line-height:40px;
            color:$highlight;
            font-size:15px;
            font-weight:bold;
            .icon{
                float:left;
                width:24px;
                height:40px;
                background:url(../img/icons.png) -113px 5px no-repeat;
            }
        } //.topic
        img{
            margin-top:10px;
        }
        table.info-table{   
            overflow:hidden;
            border-collapse:separate;
            width:100%;
            border-radius:6px;
            border:$light 1px solid;
            font-size:15px;
            thead{
                th{
                    background-color:$highlight;
                    color:$lightest;
                    border-left:#fff 1px solid;
                }
            }
            tbody{
                th{
                    font-weight:bold;
                    color:$darkest;
                }
            }
            tr:first-child{
                th,td{
                    border-top:0 none;
                }
            }
            th,td{
                padding:0 15px;
                height:45px;
                border-left:$light 1px solid;
                border-top:$light 1px solid;
                color:$dark;
                overflow:hidden;
                &:first-child{
                    border-left:0 none;
                }
            }
            td.order-products{
                padding:0;
            }
            ul.order-list{
                padding:0;
                margin:0;
                li{
                    list-style-type:none;
                    border-top:$light 1px solid;
                    &:first-child{
                        border-top:0 none;
                    }
                }
            }
            .item-name{
                width:180px;
            }
            .num{
                width:65px;
            }
            .status{
                width:100px;
            }
            span.item-name{
                width:180px;
            }
            span.num{
                padding-left:15px;
            }
            span.status{
                width:85px;
                padding-left:15px;
                line-height:35px;
                display:inline-block;
            }
            span.item-name , span.num {
                display:inline-block;
                padding-left:15px;
                line-height:35px;
                border-right:$light 1px solid;
            }
            a{
                color:$dark;
                text-decoration:underline;
            }
            .order-no{
                font-size:12px;
            }
            .delete-btn{
                position:relative;
                width:31px;
                height:31px;
                margin:0 auto;
                background:url(../img/btns.png) -449px center no-repeat;
                cursor:pointer;
                &:hover{
                    background-position:-448px -8px;
                }
            }
            tfoot{
                td{
                    &:first-child{
                        border-top:$light 1px solid;
                    }
                }
                ul{
                    text-align:right;
                    li{
                        list-style-type:none;
                        font-size:16px;
                        .price{
                            color:$highlight;
                        }
                    }
                }
            }
        } //table.info-table
        .annotation{
            font-size:11px;
            line-height:18px;
            margin-top:10px;
            padding-left:10px;
        }
        dl{
            dt{
                width:50px;
                margin-bottom:10px;
            }
            dd{
                margin-left:70px;
                margin-bottom:10px;
            }
        }
        ol{
            //padding:0;
            //margin:0;
            > ol{
                //padding:0;
                //margin:0 0 0 10px;
                li{
                    list-style-type:lower-roman;
                }
            }
        }
    } //.explanation
}