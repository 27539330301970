.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  line-height: 65px;
  padding: 0;
  margin-bottom: 0;
  float: right;
  background-color: transparent;
}
.breadcrumb > li {
  display: inline-block;
}
.breadcrumb > li + li:before {
  content: "/\00a0";
  padding: 0 5px;
  color: #666;
}
.breadcrumb > li a {
  color: #666;
  font-weight: bold;
}
.breadcrumb > .active {
  color: #666;
}