#brand-content{
    margin-bottom:40px;
    min-height:800px;
    .head{
        position:relative;
        margin-top:100px;
        height:55px;
        border-bottom:$light 1px solid;
        .title{
            float:left;
            color:$dark;
            font-size:36px;
            @media (max-width: $screen-xs-min) {
                float:none;
            }
        }
        .breadcrumb{
            @media (max-width: $screen-xs-min) {
                float:none;
                height:30px;
                line-height:30px;
                margin:0;
                padding:0;
            }
        }
        @media (max-width: $screen-xs-max) {
            height:auto;
            margin-top:230px;
            overflow:hidden;
        }
    } //.head
    
    .explanation , .story{
        position:relative;
        line-height:30px;
        padding:20px 0;
        color:$darker;
        p{
            padding:0 0 16px 0;
        }
        h4{
            font-weight:bold;
        }
        h3{
            .all{
                display: inline-block;
                overflow: hidden;
                width: 131px;
                height: 25px;
                margin-left: 20px;
                background: url(../img/all.png) 0 2px no-repeat;
                text-indent: -9999px;
            }
        }
    } //.explanation
    
    .news-column{
        position:relative;
        padding-top:10px;
        width:100%;
        > .row{
            border-top:$light 1px dotted;
            padding:30px 0;
            img.cover{
                //max-width: 248px;
                //max-height: 148px;
                width: 248px;
                height: 148px;
            }
            .news-head{
                position:relative;
                overflow:hidden;
                padding-bottom:10px;
                border-bottom:$lighter 1px solid;
                .headline{
                    line-height:24px;
                    font-size:18px;
                    color:$highlight;
                }
                .date{
                    text-align:right;
                    font-size:10px;
                    text-align:right;
                    font-family:"Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", Verdana, sans-serif;
                    padding-top:5px;
                }
            } //.news-head
            .excerpt{
                position:relative;
                margin-top:10px;
                a{
                    color:$highlight;
                }
                .more{
                    float:right;
                    height:28px;
                    line-height:28px;
                    vertical-align:middle;
                    a{
                        display:inline-block;
                    }
                    .icon{
                        display:inline-block;
                        width:20px;
                        height:28px;
                        margin-left:5px;
                        background:url(../img/btns.png) -493px center no-repeat;
                        vertical-align:top;
                    }
                    
                }
            } //.excerpt
            &:first-child{
                border-top:0 none;
            }
        }
    } //.questions
    
    .article{
        position:relative;
        margin-top:30px;
        .topic{
            position:relative;
            font-size:28px;
            color:$highlight;
        }
        .date{
            position:relative;
            font-size:12px;
            height:20px;
            color:$dark;
        }
        .content{
            position:relative;
            margin-top:20px;
            font-size:15px;
            line-height:28px;
            @media (max-width: $screen-sm-min) {
                img{
                    max-width: 100%;
                    height: auto !important;
                }
            }
        }
    }
    ul.pager{
            display: inline-block;
            padding: 10px 0;
            margin: $line-height-computed 0;
            border-top:$dark 1px solid;
            border-bottom:$dark 1px solid;
            width:100%;
            text-align:center;
            > li {
                display: inline-block;
                > a,
                > span {
                    position: relative;
                    float: left; // Collapse white-space
                    padding: $padding-base-vertical $padding-base-horizontal;
                    line-height: $line-height-base;
                    text-decoration: none;
                    color: $dark;
                    margin-left: -1px;
                }
                &:first-child {
                    > a,
                    > span {
                        margin-left: 0;
                    }
                }
                &.prev{
                    > a{
                        height:32px;
                        .icon{
                            display:inline-block;
                            height:20px;
                            width:26px;
                            background:url(../img/btns.png) -370px -17px no-repeat;
                            vertical-align:middle;
                        }
                    }
                }
                &.next{
                    > a{
                        height:32px;
                        .icon{
                            display:inline-block;
                            height:20px;
                            width:26px;
                            margin-left:10px;
                            background:url(../img/btns.png) -408px -17px no-repeat;
                            vertical-align:middle;
                        }
                    }
                }
                &.divider{
                    display: inline-block;
                    height:12px;
                    margin-bottom:10px;
                    border-left:#000 1px solid;
                }
            }
        
          > li > a,
          > li > span {
            &:hover,
            &:focus {
              color: $dark;
            }
          }
        
          > .active > a,
          > .active > span {
            &,
            &:hover,
            &:focus {
              z-index: 2;
              border-radius: 60px;
              background-color:$darkest;
              color:$lightest;
              cursor: default;
            }
          }
        
          > .disabled {
            > span,
            > span:hover,
            > span:focus,
            > a,
            > a:hover,
            > a:focus {
              color: $pagination-disabled-color;
              background-color: $pagination-disabled-bg;
              border-color: $pagination-disabled-border;
              cursor: not-allowed;
            }
          }
    }
}
@media (max-width: $screen-sm-min) {
  #brand-content .head {
    height: auto;
    margin-top: 30px;
    text-align: center;
  }
}
